import { render, staticRenderFns } from "./new-project.vue?vue&type=template&id=04aa3811&scoped=true&"
import script from "./new-project.vue?vue&type=script&lang=ts&"
export * from "./new-project.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04aa3811",
  null
  
)

export default component.exports