




















import { useMutation } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";

import BackLink from "@/app/components/Base/BackLink.vue";
import ProjectForm from "@/app/components/Project/ProjectForm.vue";
import { encodeProject } from "@/models/Project";
import { PROJECT_DATA } from "@/shared/constants/components/Project/ProjectForm";
import { useRouter } from "@/shared/hooks";
import { useNotification } from "@/shared/hooks/notification";
import { CreateNewProjectDocument, ProjectInput } from "@/shared/types/schema.types";

export default defineComponent({
  name: "new-project",
  components: {
    ProjectForm,
    BackLink, 
  },
  setup() {
    const router = useRouter();
    const { errNotification } = useNotification();

    const projectData = ref(
      _.cloneDeep(PROJECT_DATA),
    );

    const btnDisabled = computed(
      () =>
        !Object.keys(
          _.pick(projectData.value, [
            "description",
            "executorId",
            "priorityCode",
            "deadline",
            "name",
          ]),
        ).every((el) => !!projectData.value[el as keyof ProjectInput]),
    );

    const {
      mutate: createProjectMutation,
      onDone,
      onError,
    } = useMutation(CreateNewProjectDocument);


    const createProject = async(): Promise<void> => {
      await createProjectMutation(encodeProject(projectData.value));
    };

    onDone(() => {
      router && router.push("/projects");
    });

    onError((err) => {
      errNotification(err);
    });

    return {
      createProject,
      projectData,
      btnDisabled, 
    };
  },
});
